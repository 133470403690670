import { ErrorEvent } from '@sentry/types';

import { IGNORED_CLIENT_ERRORS } from './sentry.client';

export function isIgnoredError(error: string): boolean {
  return IGNORED_CLIENT_ERRORS.some((ignoredError) =>
    typeof ignoredError === 'string' ? ignoredError.includes(error) : ignoredError.test(error),
  );
}

const isErrorFromBrowser = (event: ErrorEvent, affectedBrowsers: (string | RegExp)[]) => {
  const eventBrowserName = event.contexts?.browser?.name ?? '';
  if (eventBrowserName === '' || typeof eventBrowserName !== 'string') return false;

  return affectedBrowsers.some((browser) => {
    return typeof browser === 'string' ? eventBrowserName.includes(browser) : browser.test(eventBrowserName);
  });
};

type IgnoredBrowserError = {
  errorMessage?: string;
  affectedBrowsers: (RegExp | string)[];
};

const IGNORED_ERRORS_FOR_SPECIFIC_BROWSERS: IgnoredBrowserError[] = [
  // Ignore errors from Webview and TikTok browsers
  { affectedBrowsers: [/Webview/i, /Tiktok/i] },
  {
    errorMessage: "NotFoundError: Failed to execute 'removeChild' on 'Node'",
    affectedBrowsers: ['Edge', 'Opera'],
  },
  {
    errorMessage: 'private fields are not currently supported',
    affectedBrowsers: ['Firefox'],
  },
];

export function shouldIgnoreErrorFromBrowsers(event: ErrorEvent): boolean {
  return IGNORED_ERRORS_FOR_SPECIFIC_BROWSERS.some((ignoredError) => {
    const { errorMessage, affectedBrowsers } = ignoredError;

    const isBrowserMatch = isErrorFromBrowser(event, affectedBrowsers);

    if (!isBrowserMatch) return false;

    if (errorMessage == null) return true;

    return event.message === errorMessage;
  });
}
